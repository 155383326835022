<template>
  <LoaderApp v-if="isLoading"/>
  <div v-else class="mainLayout" :style="{ backgroundImage: cFonUrl}">
    <Navbar/>
    <main class="mainContainer">
      <router-view/>
    </main>
  </div>
</template>

<script>
  import Sidebar from '../components/app/Sidebar'
  import Navbar from '../components/app/Navbar'
  import LoaderApp from '../components/LoaderApp';
  import {defineComponent} from "vue";
  import {getAuth} from "firebase/auth";

  export default defineComponent( {
    name: 'main-layout',
    components: {
      LoaderApp,
      Navbar,
      Sidebar
    },
    data:()=>({
      isLoading:false,
      intervalGecko:null,
      intervalGlobal:null,
    }),
    methods: {
    },
    computed:{
      cFonUrl(){
        if(this.$store.getters.currentInfo?.service?.fonUrl){
          return 'url(' + this.$store.getters.currentInfo.service.fonUrl + ')'
        }else return 'url(' + this.$store.state.cryptoModule.infoDefault.service.fonUrl + ')'
      }
    },
    async beforeMount() {
      this.isLoading = true
      const user = await getAuth().currentUser
      await this.$store.commit('setUser',user ? user : null);
      if(!user){return this.isLoading = false}
      await this.$store.dispatch('fetchFBInfo');//загр свои данные с FB.
      // if(this.$store.getters.infoMain.service.refreshGlobalInterval && this.$store.getters.infoMain.service.refreshCoinsInterval){
        await this.$store.dispatch('loadGlobal');//загр глоб данные.
        this.intervalGlobal = setInterval(()=>{
          this.$store.dispatch('loadGlobal');//загр глоб данные, редко.
        },36000000);//рефреш базы в интервале 60мин
        await this.$store.dispatch('loadAllGeckoCoins');//загр имен всех альтов, около 9000шт, краткое инфо.
        await this.$store.dispatch('loadMyGeckoCoinsList',{listName:'tradeList'});//загр моих альтов с Геко.
        this.intervalGecko = setInterval(()=>{
          this.$store.dispatch('loadMyGeckoCoinsList',{listName:'tradeList'});
        },600000);//рефреш базы в интервале 10мин
      // }
      // if(!this.$store.getters.infoMain.service){
      //   setTimeout(()=>{this.$store.dispatch('fetchFBInfo')},10000)
      // }
      this.isLoading = false

      // //рендер в зависимости от экрана
      // const mediaQuery = window.matchMedia("(min-width:768px)");
      // this.showMobileMenu = mediaQuery.matches;
      // const listener = e => this.showMobileMenu = e.matches;
      // mediaQuery.addListener(listener);
      // this.$once('hook:beforeDestroy', () => mediaQuery.removeListener(listener));

    },
    beforeUnmount () {//очистить обновление loadMyGeckoCoins при logout
      console.log('cleaning interval...')
      clearInterval(this.intervalGecko)
      clearInterval(this.intervalGlobal)
    },
  })
</script>

<style scoped>
  .mainLayout {
    background-size: cover;
    min-height: 100vh;
    background-attachment:fixed;
    background-position: center bottom;
    background-repeat: repeat;
  }
  .mainContainer {
    max-width:1600px;
    margin: 0 auto;
    padding: 75px 0 0 0;
  }
  .upButton{
    position: fixed;
    bottom:50px;
    right:20px;
    width:40px;
    height:40px;
  }
  .hide{
    display: none;
  }
  .upButton img{
    width:100%;
  }
  @media only screen and (max-width: 768px) {
    .mainLayout {
      /*background-size: cover;*/
      /*background-size: contain;*/
      /*background-size: 400% auto;*/
      /*background-size: 12px;*/
      /*background-size: auto;*/
      /*background-size: 3em 25%;*/
      /*background-size: auto 6px;*/
      /*background-size: auto auto;*/
      /*background-size: auto, auto;*/
      background-size: auto 1300px;
      /*background-size: 400% auto;*/

      /*background-size: 6px, auto, contain;*/
      /*background-size: inherit;*/
      /*background-size: initial;      */
      /*min-height: 1300px;*/
      /*background-attachment:fixed;*/
      background-position: center top;
    }
  }

</style>

<!--// console.log('mainLayout mounting. Loading all existing coins...')-->
<!--// await this.$store.dispatch('loadAllExistingCoinsNames');//потом загр имена всех альтов, около 9000шт, краткое инфо.-->
<!--// await this.$store.commit('loadLocal');//загр все локальное-->

<!--watch:{//ошибка в сторе есть то выводим на экран через мессаге-->
<!--error(fbError){-->
<!--// this.$error(messages[fbError.code]||'Smth wrong')-->
<!--// alert(messages[fbError.code]||'какая-то хрень')-->
<!--this.$store.commit('msgPush',{text:fbError})-->
<!--},-->
<!--},-->
