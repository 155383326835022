import generateRandomId from "@/helpers/generateRandomId";
import {readableNumber} from "@/mixins/readableNumber.mixin";
import reserveModule from './reserveModule';
import geckoModule from './geckoModule';
export default {
  modules:{
    reserveModule,
    geckoModule
  },
  state:{
    current:{
      infoName:'infoMain',
      listName:'tradeList',
      listNameSub:null,
      filter:{
        riskCategory:'all',
        industryCategory:'all',
        letter:'all',
        exchange:'all',
        label:'valueNow',
      },
    },
    listNames:[
      {real:'tradeList',show:'REAL'},
      {real:'trashList',show:'TRASH/HOLD'},
      {real:'potentialList',show:'POTENTIAL'},
      {real:'hypLists',show:'HYP'}
      ],
    riskCategories:['other','hold','safe','risky'],
    industryCategories:['other','nft related coins','metaverse','gaming','defi','dex','other'],
    exchanges:{},
    // filterTabLists:{},
    hypLists:[],
    hypFixed:0,
    infoMain: {},
    infoAK: {},
    infoDefault: {//установить изначальное состояние. Важно! Иначе ошибки с пустыми полями при рендере итд
      initialInv:0,
      cash: {binance:0, bybit:0, kucoin:0,metamask:0, bkex:0, okx:0, robinhood:0, coinbase:0 },
      hypFixed:0,
      tradeList:[],
      trashList:[],
      lastActivityList:[],
      potentialList:[],
      alarmAltsList:[],
      alarmEventsList: [],
      historyList:[],
      notesList:[],
      weightList:[],
      expensesList:[],
      fundList:[],
      fundFlowList:[],
      fundManual: {},
      fundComment: '',
      exchanges:{
        names:['other','Binance','BinanceWallet','ByBit','Ascendex','Coinbase', 'Robinhood','Metamask','Bkex','Crypto','Kucoin','Bithumb','Gate','Hitbtc'],
        binance:{name:'Binance', before:'https://www.binance.com/en/trade/', after:'_USDT?layout=pro&theme=dark&type=spot'},
        bybit:{name:'Bybit', before:'https://www.bybit.com/en-US/trade/spot/', after:'/USDT'},
        kucoin:{name:'Kucoin', before:'https://trade.kucoin.com/', after:'-USDT'},
        ascendex:{name:'Ascendex', before:'https://ascendex.com/en/cashtrade-spottrading/usdt/', after:''},
        gate:{name:'Gate', before:'https://www.gate.io/en/trade/', after:'_USDT'},
        hitbtc:{name:'Hitbtc', before:'https://hitbtc.com/', after:'-to-usdt'},
        bkex:{name:'Bkex', before:'https://www.bkex.com/trade/', after:'_USDT'},
        gecko:{name:'Gecko', before:'https://www.coingecko.com/en/coins/', after:''},
      },
      service:{
        // refreshCoinsInterval: 240000,
        // refreshGlobalInterval: 8000000,
        historyDifferenceToUpdate: 500,
        fonUrl:'https://i.pinimg.com/originals/e5/8a/e5/e58ae5ca3f368c6e490602a511c05ed2.jpg',
        firstPicUrl:'https://www.techpowerup.com/forums/attachments/1621431046431-png.200913/',
        colors:{
          navbarBgColor:'rgba(0,0,0, 0.9)',
          navbarTextColor:'rgba(255, 255, 255, 0.8)',
        },
        exchangesUrl:{
          navbarBgColor:'rgba(0,0,0, 0.9)',
          navbarTextColor:'rgba(255, 255, 255, 0.8)',
        },
      },
    },
  },
  getters:{
    listNames:state=>state.listNames,
    // filterTabLists:state=>state.filterTabLists,
    historyList:state=>state.historyList,
    hypLists:state=>state.hypLists,
    fundList:state=>state[state.current.infoName].fundList,
    expensesList:state=>state[state.current.infoName].expensesList,
    fundFlowList:state=>state[state.current.infoName].fundFlowList,
    hypFixed:state=>state.hypFixed,
    infoMain:state=>state.infoMain,
    infoAK:state=>state.infoAK,
    current:state=>state.current,
    exchanges:state=>state[state.current.infoName].exchanges,
    currentInfo:state=>state[state.current.infoName],
    currentList:state=>{
      if(state.current.listName === 'hypLists'){
        return state.hypLists.find(i=>i.title===state.current.listNameSub)?.hypTradeList || []
      }else return state[state.current.infoName][state.current.listName] || []
    },
    usdtTrades:state=>state[state.current.infoName].tradeList.find(i=>i.coin==='usdt')?.trades || [],
    alarmActivatedList:state => state[state.current.infoName].alarmAltsList?.filter(item=>{
          if(item.amount<0){return item.currentPrice>=item.desiredPrice}
          else return item.currentPrice<=item.desiredPrice
        }) || [],
  },
  mutations:{
    doc(state){
      // if(state.hypLists.length){
      //   const newList = state.hypLists.map(item=>{
      //     const newItems = item.hypTradeList.map(item2=> {
      //       return {
      //         ...item2,
      //         sparklineIn7d:[]
      //       }
      //     })
      //     return {
      //       ...item,
      //       hypTradeList:newItems
      //     }
      //   })
      // state.hypLists = newList
      //   this.dispatch('updateFBPath',{path:'hypLists',data:state.hypLists});
      // }
      // console.log('jjjj', state.hypLists)
    },
    setInfo(state, { data,info }){
      // console.log('set info:', data)
      state[info].initialInv = data?.initialInv || 1
      state[info].cash = data?.cash || state.infoDefault.cash
      // state[info].cashBinance = data?.cashBinance || 0
      // state[info].cashBybit = data?.cashBybit || 0
      // state[info].hypFixed = data?.hypFixed || 0
      state[info].tradeList = data?.tradeList || []
      state[info].trashList = data?.trashList || []
      state[info].lastActivityList = data?.lastActivityList || []
      state[info].potentialList = data?.potentialList || []
      state[info].alarmAltsList = data?.alarmAltsList || []
      state[info].alarmEventsList = data?.alarmEventsList || []
      state[info].historyList = data?.historyList || []
      state[info].notesList = data?.notesList || []
      state[info].weightList = data?.weightList || []
      state[info].expensesList = data?.expensesList || []
      state[info].fundList = data?.fundList || []
      state[info].fundFlowList = data?.fundFlowList || []
      state[info].promoList = data?.promoList || []
      state[info].fundManual = data?.fundManual || {}
      state[info].fundComment = data?.fundComment || ''
      state[info].exchanges = data?.exchanges || state.infoDefault.exchanges
      state[info].service = data?.service || state.infoDefault.service
      // console.log('set info:', state[info].promoList)
    },
    setInfoKey(state, { info,key,data }){
      // console.log('set info:', data)
      state[info][key] = data
      // console.log('set info:', state[info].promoList)
    },
    resetInfo(state, { info }){
      state[info]=state.infoDefault
    },
    setLvl(state, { data,lvl1,lvl2,lvl3,lvl4 }){
      // console.log(lvl1,lvl2,data)
      if(lvl1 && lvl2 && lvl3 && lvl4){
        state[lvl1][lvl2][lvl3][lvl4] = data
      }
      if(lvl1 && lvl2 && lvl3 && !lvl4){
        state[lvl1][lvl2][lvl3] = data
      }
      if(lvl1 && lvl2 && !lvl3 && !lvl4){
        state[lvl1][lvl2]= data
      }
      if(lvl1 && !lvl2 && !lvl3 && !lvl4){
        state[lvl1]= data
      }
      if(!lvl1 && !lvl2 && !lvl3 && !lvl4){
        alert('no lvls')
      }
      // this.commit('msgPush', { text:'data set', type:'success'});
    },
    setTradeListAsHyp(state){
      state.hypLists = state.hypLists.map(item=> {
        if(item.title === state.current.listNameSub){
          return {
            ...item,
            hypTradeList:state[state.current.infoName].tradeList
          }
        }else return item
      })
      this.dispatch('updateFBPath',{path:'hypLists',data:state.hypLists});
      this.commit('msgPush', { text:'hyp set', type:'success'});
    },
    resetFilter(state){
      state.current.filter = {
        riskCategory:'all',
        industryCategory:'all',
        letter:'all',
        exchange:'all',
        label:'valueNow',
      }
    },
    setList(state, {data,listName,toAK}){
      if(toAK){
        state.infoAK[listName]=data
        this.commit('msgPush', { text:'ak data set', type:'success'});
        this.dispatch('updateFBInfoList',{listName,toAK:true});
      }else{
        state[state.current.infoName][listName]= data;
        // console.log(info,listName, list)
        this.commit('msgPush', { text:'data set', type:'success'});
        this.dispatch('updateFBInfoList',{listName});
      }
    },
    addTrade(state, {item}){//добавление трейда в уже сущ монету. Создание новой перемещено в экшен.
      const btcPrice = this.getters.myGeckoList.find(i=>i.symbol==='btc').current_price
      const coinInMyTradeList = this.getters.currentList.find(i => i.coin === item.coin);
      // const totAm = coinInMyTradeList.trades?coinInMyTradeList.trades.reduce((a, b) => a+b):0
      const totAmArr = coinInMyTradeList.trades?.map(i=>{
        if(!i.margin) {return i.amount}else return 0
      })
      const totAm = totAmArr.length?totAmArr.reduce((a, b) => a+b):0
      const arrOfPricesByWeight = coinInMyTradeList.trades.map(i=>{
        if(!i.margin) {
          const percentage = i.amount / totAm;
          return i.price * percentage
        }else return 0
      })
      const avPrice =  arrOfPricesByWeight.length?arrOfPricesByWeight.reduce((a, b) => a+b):0;

      if(item.amount === 0){
        return alert('Продажа 0 шт. Игнор.')
      }
      if(item.amount*(-1) > totAm){
        return alert('Продажа больше чем имеется. Игнор.')
      }
      if(item.amount*(-1) === totAm){
        alert('Продажа под 0. Список обнулен. Урожай добавлен в "fixedProfit"')
        // console.log('in <0:',coinInMyTradeList)
        const profitLoss = item.amount * (-1) * (item.inBtc?item.price*btcPrice:item.price) - totAm * avPrice;
        if(!coinInMyTradeList.fixedProfitArr){
          coinInMyTradeList.fixedProfitArr = [profitLoss]//создаем начальную запись
        }else
          coinInMyTradeList.fixedProfitArr.push(profitLoss)//добавляем нов запись
          coinInMyTradeList.trades = [];
          coinInMyTradeList.fixedProfit = coinInMyTradeList.fixedProfit + profitLoss;
      }else{
        const tradeItem = {
          amount:item.amount,
          price:item.inBtc?item.price*btcPrice:item.price,
          comment:item.comment,
          id:item.id,
          date:item.date,
          margin:item.margin,
          ls:item.ls,
        }
        // if(!coinInMyTradeList.trades){
        //   console.log('no trades, creating new trades arr',[tradeItem])
        //   coinInMyTradeList.trades = [tradeItem]//создаем начальную запись
        // }else {
        //   coinInMyTradeList.trades.push(tradeItem)//добавляем нов запись
        //   console.log('adding to existing trades arr')
        // }
        coinInMyTradeList.trades = [...coinInMyTradeList.trades,tradeItem]
      }
      //добавка трейда в юсдт
      const usdtTrade = {
        amount:item.amount * (item.inBtc?item.price*btcPrice:item.price) *(-1),//для койнов плюс и соотв для юсд минус и наоборот
        price:1,//1долл за 1 долл
        comment:'trade: '+item.coin.toUpperCase(),
        id:generateRandomId(),
        date:item.date,
      }
      const btcTrade = {
        amount:item.amount * (item.inBtc?item.price*btcPrice:item.price) *(-1),
        price:item.price,
        comment:'trade: '+item.coin.toUpperCase(),
        id:generateRandomId(),
        date:item.date,
      }
      //добавка трейда в last actions
      if(state.current.listName==='tradeList') {
        if(item.inBtc){
          console.log('btcTrade',btcTrade)
          let newBtcObj = state[state.current.infoName].tradeList.find(i=>i.coin==='btc')
          newBtcObj = {...newBtcObj, trades : newBtcObj.trades.push(btcTrade) }
        }else{
          // console.log('usdtTrade',usdtTrade)
          // if(!item.margin){
          //   let newUsdtObj = state[state.current.infoName].tradeList.find(i=>i.coin==='usdt')
          //   newUsdtObj = {...newUsdtObj, trades : newUsdtObj.trades.push(usdtTrade) }
          //   // state[state.current.infoName].cash = state[state.current.infoName].cash + usdtTrade.amount
          // }
        }
        state[state.current.infoName].lastActivityList.unshift(//добавляем activity запись в начало
          {
            amount: item.amount,
            id: generateRandomId(),
            date: item.date,
            comment: item.comment,
            coin: item.coin,
            price: item.inBtc ? item.price * btcPrice : item.price,
            currentPrice: coinInMyTradeList.currentPrice,
            coinUrl: coinInMyTradeList.coinUrl,
            name: coinInMyTradeList.name,
            margin:item.margin,
            ls:item.ls,
          }
        )
        this.dispatch('updateFBInfoList',{listName:'lastActivityList'});
      }
      this.dispatch('updateFBInfoList',{listName:state.current.listName});
      this.commit('msgPush', { text:'trade added', type:'success'});
    },
    addAlarm(state, {item,listName}){
      if(listName && listName === 'alarmEventsList'){
        state[state.current.infoName][listName].push(
          {
            date:item.date,//расписал чтоб было смотреть здесь а не в комп
            comment:item.comment,
            id:item.id,
          }
        )
        this.dispatch('updateFBInfoList',{listName:listName});
      }else{//в ост случаях это альты
        const coinInMyTradeList = state[state.current.infoName].tradeList.find(Titem => Titem.coin === item.coin);
        // console.log('coinInMyTradeList',coinInMyTradeList,item.coin)
        const coinInMyPotentialList = state[state.current.infoName].potentialList.find(Pitem => Pitem.coin === item.coin);
        if(!coinInMyTradeList && !coinInMyPotentialList){
          return alert('Нет монеты в трейд и потен листах. Игнор')
        }
        if(item.amount===0){
          return alert('Продажа/покупка 0 шт. Игнор.')
        }
        state[state.current.infoName].alarmAltsList.push(
          {
            amount:item.amount,
            desiredPrice:item.desiredPrice,
            id:item.id,
            date:item.date,
            comment:item.comment,
            coin:item.coin,
            currentPrice:coinInMyTradeList.currentPrice || coinInMyPotentialList.currentPrice,
            coinUrl:coinInMyTradeList.coinUrl || coinInMyPotentialList.coinUrl,
            name:coinInMyTradeList.name || coinInMyPotentialList.name,
          }
        )
        this.dispatch('updateFBInfoList',{listName:'alarmAltsList'});
      }
      this.commit('msgPush', { text:'alarm set', type:'success'});
    },
    deleteCatalog(state, {tab}){
      state[state.current.infoName].notesList = state[state.current.infoName].notesList.filter(item=>item.title!==tab)
    },
    copyFundToInfoAK(state){
      state.infoAK.fundManual = state.infoMain.fundManual
      state.infoAK.fundList = state.infoMain.fundList
      state.infoAK.fundListDeposits = state.infoMain.fundListDeposits
    },
    setFundWeek(state, { item }){
      // console.log('item',item)
      state[state.current.infoName].fundList = state[state.current.infoName].fundList.map(i=>{
        if(i.id===item.id){
          return item
        }else return i
      })
    },
    setMonth(state, { item }){
      state[state.current.infoName].expensesList = state[state.current.infoName].expensesList.map(i=>{
        if(i.id===item.id){
          return item
        }else return i
      })
    },
    setDumpPrices(state){
      state[state.current.infoName][state.current.listName] = state[state.current.infoName][state.current.listName].map(item=>{
        if(item.currentPrice>item.dumpPrice){return item}
        else return {
          ...item,
          dumpPrice:item.currentPrice,
          // dXs:readableNumber(item.currentPrice / item.dumpPrice,1)
        }
      })
      this.dispatch('updateFBInfoList',{listName:state.current.listName});
      this.commit('msgPush',{text:'dump prices corrected', type:'success'});
    },
    filterHistory(state, {diff}){
      const before = state[state.current.infoName].historyList.length
      console.log('history length before:',state[state.current.infoName].historyList.length)
      state[state.current.infoName].historyList = state[state.current.infoName].historyList.filter(item=>{
        // const prevValue = this.$store.state.history[this.$store.state.history.indexOf(item-1||0)];
        const prevIndex = state[state.current.infoName].historyList.indexOf(item)-1;
        if(prevIndex===-1){//оставить первое знач
          return true;
        }
        if(prevIndex===before-2){//оставить посл знач
          return true;
        }
        const prevValue = state[state.current.infoName].historyList[prevIndex].totalNow;
        const isNegative = item.totalNow < prevValue;//след число стало меньше чем пред?
        if(isNegative){//да
          return item.totalNow<prevValue-diff;
        }else {//нет
          return item.totalNow>prevValue+diff;
        }
      });
      const after = state[state.current.infoName].historyList.length
      this.dispatch('updateFBInfoList',{listName:'historyList'});
      this.commit('msgPush',{text:'history reduced from '+before+' to '+after, type:'success'});
    },
    moveToTrashList(state, {coin}){
      const coinObjInTradeList = state[state.current.infoName].tradeList.find(item=>item.coin === coin);
      state[state.current.infoName].trashList.push(coinObjInTradeList)
      const usdtTrade = {
        amount:1,//помечаем потери 1 долларом ибо 0 нельзя. Пример покупка экв 100$ за 1 доллар
        price:coinObjInTradeList.invested,
        comment:coin.toUpperCase() + ' moved to trashList',
        id:generateRandomId(),
        coin:coin,
        date:new Date().toLocaleString('en-US'),
      }
      const newTradeList = state[state.current.infoName].tradeList.filter(item=>item.coin !== coin)
      let newUsdtObj = newTradeList.find(item=>item.coin==='usdt')
      newUsdtObj = {...newUsdtObj, trades : newUsdtObj.trades.push(usdtTrade) }
      state[state.current.infoName].tradeList = newTradeList
      // this.commit('calculate',{coin:'usdt'});
      this.dispatch('updateFBInfoList',{listName:'tradeList'});
      this.dispatch('updateFBInfoList',{listName:'trashList'});
      this.commit('msgPush',{text:'coin moved to trashList + USDT comment added', type:'success'});
    },
    moveBackToTradeList(state, {coinItem}){
      // const coinObjInTrashList = state[state.current.infoName].trashList.find(item=>item.coin === coin);//найти койн в корзине
      // state[state.current.infoName].tradeList.push(coinObjInTrashList)//вставить его в основной список
      // state[state.current.infoName].trashList = state[state.current.info].trashList.filter(item=>item.coin !== coin)//удалить из корзины
      // const usdtObj = state[state.current.infoName].tradeList.find(item=>item.coin === 'usdt')//найти ЮСДТ обьект в кот надо удалить запись
      // const tradeId = usdtObj.trades.find(item=>item.comment===coin.toUpperCase()+ ' moved to trashList').id//найти у этого обьекта айди трейда кот нужно удалить
      // this.commit('deleteTrade',{coin:'usdt',tradeId:tradeId})
      // // this.commit('calculate',{coin:coin});
      // this.dispatch('updateFBInfoList',{listName:'tradeList'});
      // this.dispatch('updateFBInfoList',{listName:'trashList'});
      // this.commit('msgPush',{text:'to tradeList + del USDT comment', type:'success'});
      state[state.current.infoName].tradeList.push(coinItem)
      state[state.current.infoName].trashList = state[state.current.infoName].trashList.filter(i=>i.id !== coinItem.id)
      const usdtObj = state[state.current.infoName].tradeList.find(i=>i.coin === 'usdt')//найти ЮСДТ обьект в кот надо удалить запись
      const usdtObjTradeId = usdtObj.trades.find(i=>i.comment===coinItem.coin.toUpperCase()+ ' moved to trashList').id//найти у этого обьекта айди трейда кот нужно удалить
      usdtObj.trades = usdtObj.trades.filter(i => i.id !== usdtObjTradeId)
      this.dispatch('updateFBInfoList',{listName:'tradeList'});
      this.dispatch('updateFBInfoList',{listName:'trashList'});
      this.commit('msgPush',{text:'to tradeList + del USDT comment', type:'success'});
    },
    updateListWithMyGecko(state,{listName, info='infoMain',geckoList}) {// добавление в сущ лист свеж инфы с базы. Только если база и лист не пустые. Минимум [].
      if(geckoList.length && state[info] && state[info][listName] && state[info][listName].length){
        state[info][listName] = state[info][listName].map(item => {
          const coinObjInGecko = geckoList.find(itemGecko=>itemGecko.symbol === item.coin);
          if(coinObjInGecko) {//если найдена моя монета в моей базе то дополняем сущ лист ценами с базы. Если не найдена то ничего не дел.
            // console.log('addFreshPrices, if coinObjInGecko:', coinObjInGecko);
            let refreshedItem;
            if(listName==='tradeList' || listName==='trashList' || listName==='potentialList'){
              refreshedItem = {
                ...item,
                coinUrl: coinObjInGecko.image,//на случай если ранее была заплатка.
                athPrice: coinObjInGecko.ath,
                athPerc: coinObjInGecko.ath_change_percentage,
                currentPrice: coinObjInGecko.current_price,
                priceChangePercentage24hOriginal: coinObjInGecko.price_change_percentage_24h,
                priceChangePercentage24h: Math.round(coinObjInGecko.price_change_percentage_24h),
                marketCapRank: coinObjInGecko.market_cap_rank||0,
                sparklineIn7d: coinObjInGecko.sparkline_in_7d.price.map(n=>readableNumber(n)),
              }
            }
            if(listName==='lastActivityList' || listName==='alarmAltsList'){
              refreshedItem = {
                ...item,
                currentPrice: coinObjInGecko.current_price,
              }
            }
            return refreshedItem
          }else return item
        })
      }
      if(listName==='hypLists' && state.hypLists?.length){//2лвлa поэтому отдельно
        state.hypLists = state.hypLists.map(itemLvl1 => {
          itemLvl1.hypTradeList = itemLvl1.hypTradeList?itemLvl1.hypTradeList.map(itemLvl2=>{
            const coinObjInGecko = geckoList.find(itemGecko=>itemGecko.symbol === itemLvl2.coin);
            if(coinObjInGecko) {
              return {
                ...itemLvl2,
                currentPrice: coinObjInGecko.current_price,
                priceChangePercentage24h: Math.round(coinObjInGecko.price_change_percentage_24h),
              }
            }else return itemLvl2
          }):[]
          return itemLvl1
        })
      }
    },
    deleteTrade(state, {coin,tradeId}){//удалить часть либо весь койн
     const coinObj = this.getters.currentList.find(i=>i.coin === coin.toLowerCase());//койн полюбому есть
      if(coinObj.trades.length===1) {//удалить полностью койн если это посл итем
        if (this.getters.current.listName === 'hypLists') {
          state.hypLists = state.hypLists.map(i => {
            if (i.title === state.current.listNameSub) {
              return {
                ...i,
                hypTradeList: i.hypTradeList.filter(item => item.coin !== coin)
              }
            } else return i
          })
        } else {
          state[state.current.infoName][state.current.listName] = state[state.current.infoName][state.current.listName].filter(item => item.coin !== coin)
        }
      }else{
        coinObj.trades = coinObj.trades.filter(item => item.id !== tradeId);
      }
      // if(this.getters.current.listName === 'hypLists') {
      //   this.dispatch('updateFBPath', {path: 'hypLists', data: state.hypLists});
      // }else this.dispatch('updateFBInfoList',{listName:state.current.listName});
    },

    sellBackTrade(state, {coin,tradeId,sellPrice,comment}){//зафиксировать сделку
      const coinObj = this.getters.currentList.find(item=>item.coin===coin.toLowerCase());//койн полюбому есть
      // console.log('coin obj to operate',coinObj)
      const tradeItem = coinObj.trades.find(item=>item.id===tradeId);
      const pl = tradeItem.amount * sellPrice - tradeItem.amount * tradeItem.price;
      if(!coinObj.fixedProfitArr){
        coinObj.fixedProfitArr = [pl]
      }else coinObj.fixedProfitArr.push(pl);
      coinObj.fixedProfit = coinObj.fixedProfitArr.length?coinObj.fixedProfitArr.reduce((a, b) => a+b):0;

      if(coinObj.trades.length ===1 && coinObj.fixedProfit !== 0) {//заменить койн если есть fixed
        const ok = confirm('delete coin and move p/l to USDT (yes) or keep it here (no)?')
        if (ok) {
          this.getters.usdtTrades.push({
            amount: coinObj.fixedProfit,
            price: 1,//1долл за 1 долл
            comment: 'p/l from trade: ' + coin.toUpperCase(),
            id: generateRandomId(),
            date: new Date().toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}),
          })
          this.commit('deleteTrade', {coin: coin, tradeId: tradeId});
        } else {
          coinObj.trades = [{
            amount: 0,
            price: 0,
            comment: 'p/l',
            id: generateRandomId(),
            date: new Date().toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}),
          }]
        }
      }else this.commit('deleteTrade', {coin: coin, tradeId: tradeId});

      this.commit('msgPush', { text:'trade sold back', type:'success'});
      // if(state.current.listName==='tradeList'){//добавка трейда в юсдт
      //   const usdtTrade = {
      //     amount:tradeItem.amount * sellPrice,
      //     price:1,//1долл за 1 долл
      //     comment:'sellback: '+coin.toUpperCase(),
      //     id:generateRandomId(),
      //     date:new Date().toLocaleString('en-US'),
      //   }
      //   console.log('usdtTrade',usdtTrade)
      //   let newUsdtObj = state[state.current.infoName][state.current.listName].find(item=>item.coin==='usdt')
      //   newUsdtObj = {...newUsdtObj, trades : newUsdtObj.trades.push(usdtTrade) }
      //   // state[state.current.infoName].cash = state[state.current.infoName].cash + usdtTrade.amount
      //   //добавка трейда в las actions
      //   state[state.current.infoName].lastActivityList.unshift(//добавляем activity запись в начало
      //     {
      //       amount:-tradeItem.amount,
      //       id:generateRandomId(),
      //       date:new Date().toLocaleString('en-US'),
      //       coin:coinObj.coin,
      //       currentPrice:coinObj.currentPrice,
      //       price:sellPrice,
      //       coinUrl:coinObj.coinUrl,
      //       name:coinObj.name,
      //       comment
      //     }
      //   )
      //   // this.commit('calculate',{coin:'usdt'});
      //   this.dispatch('updateFBInfoList',{listName:'lastActivityList'});
      // }
    },
    deleteItem(state, {id,listName}){
      state[state.current.infoName][listName] = state[state.current.infoName][listName].filter(item=>item.id!==id)
      this.commit('msgPush', { text:'item deleted', type:'success'});
      this.dispatch('updateFBInfoList',{listName:listName});
    },
    deleteItemLvl(state, {id,lvl1}){
      state[lvl1] = state[lvl1].filter(item=>item.id!==id)
      this.commit('msgPush', { text:'item deleted', type:'success'});
      this.dispatch('updateFBPath',{path:lvl1,data:state[lvl1]});
    },
  },
  actions: {
  }
}
