import { createStore } from "vuex";
import FB from './FB';
import resumeModule from './resumeModule';
import cryptoModule from './crypto/cryptoModule';

const store = createStore({
  modules: {
    FB,
    resumeModule,
    cryptoModule,
  },
  state: {
    user: {
      isSwitched:false,
      letter:null,
      isA:null,
      isK:null,
      isAorK:null,
      email:null,
      emailVerified:null,
      isAnonymous:null,
      displayName:'Гость',
      phoneNumber:null,
      photoURL:'/img/userW.png',
    },
    isLoading:{
      trade:false,
      gecko:false,
    },
    msgs:[],// {text:'',type:''},
  },
  getters:{
    user:state=>state.user,
  },
  mutations:{
    setUser(state, user){
      if(user){
        if(user.uid){
          state.user.id = user.uid
          state.user.isA = user.uid === process.env.VUE_APP_FIREBASE_A_ID
          state.user.isK = user.uid === process.env.VUE_APP_FIREBASE_K_ID
          state.user.isAorK = user.uid === process.env.VUE_APP_FIREBASE_A_ID || user.uid === process.env.VUE_APP_FIREBASE_K_ID
          state.user.letter = user.uid === process.env.VUE_APP_FIREBASE_A_ID?'A':user.uid === process.env.VUE_APP_FIREBASE_K_ID?'K':null
        }
        if(user.email){state.user.email = user.email}
        if(user.displayName){state.user.displayName = user.displayName}
        if(user.emailVerified){state.user.emailVerified = user.emailVerified}
        if(user.isAnonymous){state.user.isAnonymous = user.isAnonymous}
        if(user.phoneNumber){state.user.phoneNumber = user.phoneNumber}
        if(user.photoURL){state.user.photoURL = user.photoURL}
      }
    },
    resetUser(state){
      state.user={
        isSwitched:false,
        letter:null,
        isA:null,
        isK:null,
        isAorK:null,
        email:null,
        emailVerified:null,
        isAnonymous:null,
        displayName:'Гость',
        phoneNumber:null,
        photoURL:'/img/userW.png',
      }
    },
    switchInfo(state){
      state.cryptoModule.current.infoName = JSON.parse(JSON.stringify(state.cryptoModule.current.infoName==='infoMain'?'infoAK':'infoMain'))//deep clone
      // state.cryptoModule.current.infoName = state.cryptoModule.current.infoName==='infoMain'?'infoAK':'infoMain'
      state.user.isSwitched = !state.user.isSwitched
      state.user.letter = state.user.letter==='A'?'K':'A'
    },
    msgPush(state, {text,type}){
      state.msgs.push({//unshift
        text,
        type,
      });
      setTimeout(() => {
        state.msgs.shift();//pop
      }, type==='error'?7000:3000);
    },
  },
  actions: {
  }
});
export default store

//пример соединения множества запросов
// return axios.all([
//   axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=false'),
//   axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=2&sparkline=false'),
//   axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=3&sparkline=false'),
//
// ])
//   .then(axios.spread((res1, res2,res3) => {
//     const res = res1.data
//       .concat(res2.data)
//       .concat(res3.data)
//     context.dispatch('getSparklines', res);
//     context.commit('updateCoinGecko', res);
//     state.isLoadingSuccess=true;
//   }))
//---------------------------
// loadCoinGecko(context) {
//   state.isLoading=true;
//   return axios.all([
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=2&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=3&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=4&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=5&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=6&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=7&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=8&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=9&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=10&sparkline=false'),
//     axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=11&sparkline=false'),
//   ])
//     .then(axios.spread((res1, res2,res3, res4,res5,res6, res7, res8, res9,res10,res11) => {
//       const res = res1.data
//         .concat(res2.data)
//         .concat(res3.data)
//         .concat(res4.data)
//         .concat(res5.data)
//         .concat(res6.data)
//         .concat(res7.data)
//         .concat(res8.data)
//         .concat(res9.data)
//         .concat(res10.data)
//         .concat(res11.data)
//       // context.commit('updateCoinGecko', res);
//       console.log('comp:', res.find(item=>item.symbol==='btc'))
//       state.isLoadingSuccess=true;
//     }))
//     .catch((err) => {
//       // context.commit('loadSavedGecko');
//       console.log('errorRRR from actions loadCoinGecko:', err);
//       state.isLoadingFailed=true;
//     })
//     .then(()=>{
//       state.isLoading=false;
//       // context.commit('updateTradeListWithGecko')
//       // context.commit('updatepotentialListWithGecko')
//     })
// },
